.inbox {
    svg {
        width: 100%;
    }

    &__see_all {
        font-family: Oswald;
        color: #fff;
    }

    .circle {
        background-color: #D7B367 !important;
        color: #fff !important;
        font-size: 12px !important;
        width: auto !important;
        height: auto !important;
        display: inline-block !important;
        line-height: 1.3 !important;
        right: auto !important;
        left: calc(100% - 20px);

        span {
            padding: 15px 5px 12px;
        }
    }

    &__container {
        position: absolute;
        top: 90%;
        width: 300px;
        left: -106px;
        text-align: center;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.4s ease, visibility 0s ease 0.4s;
        margin-top: 30px;

        &.active {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.4s ease, visibility 0s ease;
        }

        @media all and (max-width: 1023px) {
            left: -235px;
            top: auto;
            
            .right_content & {
                display: none;
            }
        }

        @media all and (max-width: 640px) {
            left: 0;
            right: 0;
            width: 100vw;
        }
    }

    &__wrapper {
        margin-top: -25px;
        box-shadow: 1px 2px 16px rgba(0, 0, 0, 0.14);
        border-radius: 5px;
        background: #111617;

        // @media all and (max-width: 1023px) {
        //     margin-top: 0;
        // }
    }

    &__header {
        padding: 20px 5% 10px;
    }

    &__body {
        max-height: 385px;
        overflow: auto;

        @media all and (max-width: 640px) {
            max-height: 360px;
        }
    }

    &__item {
        @apply px-6 py-5 flex items-center;

        &.unread {
            background-color: #494949;
        }

        &--detail {
            .inner-text {
                // color: #fff;
                // @apply font-oswald-text text-sm;
                color: #FFF;
                font-family: Oswald;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 19px; /* 118.75% */
                letter-spacing: -0.23px;
            }
        }

        &--time {
            color: #FFF;
            font-family: Oswald;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 19px; /* 135.714% */
            letter-spacing: -0.23px;
            opacity: 0.5;
            padding-left: 0 !important;
        }
    }
}

::-webkit-scrollbar,
.scroll_bar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb,
.scroll_bar::-webkit-scrollbar-thumb {
    border: 0 solid #fff;
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, .24);
    border-radius: 20px;
    background: #fff;
}
