.switch_dialog .content {
    padding: 30px 40px 0;
}

.switch_dialog .verify_button {
    background: #EF0048;
    border-radius: 3px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    text-transform: unset;
    padding: 15px 20px;
}

.switch_dialog .verify_button:hover {
    background: #EF0048;
}

.switch_dialog .verify_button[disabled] {
    cursor: no-drop;
    opacity: 0.4;
    color: #fff;
}

.switch_dialog .letter_spacing input {
    letter-spacing: 40px;
}
