.special-card.MuiPaper-elevation1 {
    background-color: transparent;
    box-shadow: none;
    min-height: 150px;
}

.special-card.MuiPaper-elevation1 > div {
    min-height: 150px;
}

.CircularProgressbar .CircularProgressbar-text {
    font-family: 'Oswald';
    font-weight: 700;
    color: #5f6786;

}

@media (max-width: 1023px) {
    .special-card.MuiPaper-elevation1 {
        min-height: 250px;
    }
}

@media (max-width: 767px) {
    .special-card.MuiPaper-elevation1 {
        min-height: 200px;
    }

    .special-card.MuiPaper-elevation1 > div {
        min-height: 150px;
    }
}