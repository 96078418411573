.public-bio__photo {
    width: 200px;
    height: 200px;
    background-color: #fff;
    border-radius: 100px;
    overflow: hidden;
}

.public-bio__image {
    object-fit: cover;
    width: 200px;
    height: 200px;
}