.button_div > button {
    min-width: 0;
    margin: 0 0.5rem;
    padding: 10px 20px;
    font-size: 13px;
    text-transform: none;
    font-family: 'Oswald';
}

.button_div .change_password {
    background-color: #EF0048;
    color: #fff !important;
}

.button_div .cancel_button {
    background-color: #111617;
    color: rgba(255, 255, 255, 0.5);
    font-family: 'Oswald';
}
