.text_field {
    width: 100%;
    position: relative;
}

.text_field > div {
    height: 2.573rem;
    font-weight: 100;
}

.text_field > p {
    font-size: 12px;
    font-weight: 300;
    line-height: 30px;
    position: absolute;
    bottom: -20px;
}

.text_field input,
.text_area input {
    padding: .429rem 1rem;
    font-size: 1rem;
    line-height: 1.571429;
    color: #a1a1a1;
    font-family: 'Oswald';
    font-weight: 600;
    height: 100%;
    box-sizing: border-box;
}

.text_field fieldset,
.text_area fieldset {
    border-radius: .215rem;
    border-color: #e4eaec;
}

.ex_network .text_field > div:hover fieldset,
.dialog .text_field > div:hover fieldset {
    border-color: #e4eaec;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
}

.text_field .error,
.text_area .error {
    display: flex;
    justify-content: space-between;
}

.text_field .error .icon,
.text_area .error .icon {
    width: 16px;
    margin-bottom: 10px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    border: unset;
    -webkit-text-fill-color: #76838f;
    caret-color: #76838f;
    transition: background-color 5000s ease-in-out 0s;
}
