.nav_bar {
    position: fixed;
    background: #EF0048;
    /* background: rgba(0,160,173, 0.6); */
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    z-index: 111;
}

.nav_bar .logo {
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-size: 32px;
    line-height: 37px;
    text-align: center;
    letter-spacing: 0.0015em;
    color: #FFFFFF;
}

.nav_bar .tab img {
    width: 150px;
    padding-top: 10px;
}

.nav_bar .nav_content {
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
}

.nav_bar .horizontal_tabs {
    background: unset;
    box-shadow: unset;
    width: unset;
}

.horizontal_tabs .tabs_content {
    min-height: 70px;
}

.horizontal_tabs .tabs_content .tab {
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.0015em;
    color: #FFFFFF;
    text-transform: unset;
    border-bottom: 3px solid transparent;
}

.horizontal_tabs .tabs_content .tab.active_tab {
    opacity: 1;
    /* border-color: #FFFFFF; */
}

.horizontal_tabs .tabs_content > div > span {
    background: #FFFFFF;
    margin-bottom: 2px;
    height: 4px;
    border-radius: 5px 5px 0 0;
}

.horizontal_tabs .tab > span:first-child {
    flex-direction: row;
}

.nav_bar .horizontal_tabs .tab .MuiTab-wrapper {
    align-items: center;
    font-family: 'Oswald';
    font-size: 15px;
}

.nav_bar .horizontal_tabs .tab .icon {
    max-width: 25px;
    margin: 0 5px 0 0;
    fill: #FFFFFF;
    width: 100%;
    flex: 1 1 0%;
}

.nav_bar .notification > span:first-child {
    position: relative;
}

.nav_bar .notification .icon {
    max-width: 24px;
}

.nav_bar .inbox .icon {
    max-width: 24px;
}

.nav_bar .verify .icon {
    width: 30px;
}

.nav_bar .notification .circle {
    position: absolute;
    background: #FFFFFF;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    top: -2px;
    right: 0;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 9px;
    color: #3E8EF7;
}

.nav_bar .inbox .circle {
    position: absolute;
    background: #FFFFFF;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    top: 10px;
    right: -4px;
    font-family: Roboto,sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 9px;
    color: #3E8EF7;
}

.nav_bar .nav_content .right_content {
    display: flex;
    align-items: center;
    position: relative;
}

.nav_bar .toggle_button {
    position: absolute;
    right: 20px;
    color: #fff;
}

.nav_bar .hide_button {
    width: 100%;
    color: #fff;
    text-align: left;
}

.nav_bar .hide_button > span:first-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.nav_bar .layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000078;
}

.nav_bar .hide_button,
.nav_bar .toggle_button,
.nav_bar .layer {
    display: none;
}

.tabs_content .MuiTab-root {
    min-width: 0;
    margin-right: 5px;
}

@media (max-width: 1400px) {
    .right_content .MuiIconButton-root {
        padding: 8px
    }

    .profile .profile_button, .profile .profile_button:hover {
        padding: 6px 5px;
    }
}

@media (max-width: 1300px) {
    .nav_bar .tab img {
        width: 150px;
    }

    .nav_bar .nav_content {
        margin-left: 20px;
    }

    .horizontal_tabs .tabs_content .tab {
        padding: 9px 6px 6px;
        margin-right: 5px;
    }

    .nav_bar .horizontal_tabs .tab .MuiTab-wrapper {
        font-size: 15px;
    }

    .nav_bar .horizontal_tabs .tab .icon {
        max-width: 25px;
    }
}

@media all and (max-width: 1281px) {
    .nav_bar .tab img {
        width: 120px;
    }

    .right_content .MuiIconButton-root {
        padding: 8px;
    }

    .nav_bar .nav_content {
        margin-left: 20px;
    }

    .nav_bar .horizontal_tabs .tab .icon {
        max-width: 25px;
        margin-right: 8px;
    }
    
    .nav_bar .horizontal_tabs .tab .MuiTab-wrapper {
        font-size: 14px;
    }

    .tabs_content .MuiTab-root {
        margin-right: 8px;
    }

    .horizontal_tabs .tabs_content .tab {
        padding-left: 6px;
        padding-right: 6px;
    }

    .profile .profile_button, .profile .profile_button:hover {
        padding: 6px 0;
    }

    .right_content .profile .profile_button .name {
        font-size: 14px;
        line-height: 1.3;
        margin-left: 8px;
    }
    .right_content .profile__info span {
        font-size: 10px;
        line-height: 1.3;
        margin-left: 8px;
    }
}

/* @media (max-width: 1025px) {
    .nav_bar .logo {
        font-size: 28px;
    }

    .horizontal_tabs .tabs_content .tab {
        font-size: 16px;
        min-width: max-content;
    }

    .horizontal_tabs .tab .icon {
        width: 20px;
    }

    .nav_bar .nav_content {
        margin-left: 10px;
    }

    .nav_bar .tab img {
        width: 130px;
    }

    .nav_bar .horizontal_tabs .tab .MuiTab-wrapper {
        font-size: 13px;
    }

    .nav_bar .horizontal_tabs .tab .icon {
        margin-right: 5px;
        max-width: 22px;
    }
} */

@media (max-width: 1124px) {
    .nav_bar .logo {
        font-size: 23px;
    }

    .nav_bar .hide_button,
    .nav_bar .toggle_button,
    .nav_bar .layer {
        display: unset;
    }

    .nav_bar .nav_content,
    .nav_bar .nav_content .right_content > button {
        display: none;
    }

    .nav_bar .nav_content.show {
        position: fixed;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        top: 0;
        background: #111617;
        height: 100%;
        box-shadow: 4px 4px 22px rgba(0, 0, 0, 0.19);
        margin: unset;
        width: 250px;
        z-index: 1;
    }

    .horizontal_tabs .tabs_content .tab {
        width: 100%;
    }

    .horizontal_tabs .tabs_content .tab > span:first-child {
        justify-content: flex-start;
    }

    .nav_bar .nav_content .right_content {
        position: absolute;
        bottom: 0;
    }

    .nav_bar .horizontal_tabs {
        padding-left: 5%;
        padding-right: 5%;
    }

    .profile {
        padding: 10px 5%;
    }
    .nav_bar {
        height: 115px;
        flex-wrap: wrap;
    }
}

@media (max-width: 769px) {
    .nav_bar .logo {
        font-size: 23px;
    }

    .nav_bar .hide_button,
    .nav_bar .toggle_button,
    .nav_bar .layer {
        display: unset;
    }

    .nav_bar .nav_content,
    .nav_bar .nav_content .right_content > button {
        display: none;
    }

    .nav_bar .nav_content.show {
        position: fixed;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        top: 0;
        background: #111617;
        height: 100%;
        box-shadow: 4px 4px 22px rgba(0, 0, 0, 0.19);
        margin: unset;
        width: 250px;
        z-index: 1;
    }

    .horizontal_tabs .tabs_content .tab {
        width: 100%;
    }

    .horizontal_tabs .tabs_content .tab > span:first-child {
        justify-content: flex-start;
    }

    .nav_bar .nav_content .right_content {
        position: absolute;
        bottom: 0;
    }
}


@media (max-width: 767px) {
    .nav_bar {
        height: 105px;
    }

    .nav_bar .tab img {
        padding-top: 0;
    }

    .nav_bar .horizontal_tabs .tab .icon {
        max-width: 25px;
    }
}
