.deals .table {
    margin: 50px 0;
    position: relative;
}

.table .heading {
    position: absolute;
    top: 20px;
    left: 0;
    z-index: 1;
    font-family: Roboto, sans-serif;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
}

.deals table tbody .hash_text {
    display: flex;
}

.deals .table .view_details_button {
    font-size: 14px;
    font-family: 'Oswald';
    letter-spacing: -0.23px;
    line-height: 20px;
    border-radius: 5px;
    border: 1px solid #858787;
    text-transform: unset;
    padding: 8px 18px;
    background-color: #0C0F0F;
    color: #FFF;
}

.deals .table .new_deal {
    background: #EF0048;
    border-radius: 5px;
    font-family: 'Oswald';
    font-size: 16px;
    line-height: 1;
    color: #FFFFFF;
    padding: 15px 20px;
    text-transform: unset;
    margin-left: 10px;
}

.deals .table .new_deal:before {
    content: '';
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAPCAYAAADUFP50AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABGSURBVHgB7ZHBCQAgDAOjOIj7L6WbRMU+TUV/ggehj6MUUkBAsozgFBrKR1zy0GLaNad88JpzL/Zk4apN5df8P85WFdlxaHUTJLoIt0gZAAAAAElFTkSuQmCC');
    background-size: cover;
    width: 14px;
    height: 14px;
    display: inline-block;
    margin-right: 10px;
}

.MuiOutlinedInput-notchedOutline {
    display: none;
}

@media (max-width: 769px) {
    .deals .table .view_details_button {
        margin-top: 20px;
    }

    .deals .table .new_deal {
        margin: 20px 0;
    }
}