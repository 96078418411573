.dialog.account_type .content {
    padding: 40px 50px;
    width: 500px;
}

.dialog .heading {
    font-family: Roboto, sans-serif;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
}

.dialog .content .row {
    display: flex;
    flex-direction: row;
    margin: 30px 0;
    justify-content: space-between;
}

.dialog .content .row label > span:last-child {
    font-family: Roboto, sans-serif;
    font-size: 20px;
    line-height: 23px;
    color: #696969;
}

.dialog .content .row label span[aria-disabled='true'],
.dialog .content .row label span[aria-disabled='true'] + span {
    cursor: no-drop;
    opacity: 0.4;
}

.dialog .button_div {
    justify-content: center;
    padding-bottom: 40px;
}

.dialog .generate_code {
    background: #003B42;
    opacity: 0.98;
    border-radius: 10px;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    padding: 14px 20px;
    text-transform: unset;
}

.dialog .generate_code:hover {
    background: #003b42bf;
}

.dialog .radio_icon {
    display: flex;
    align-items: center;
}

.dialog .radio_icon .icon {
    width: 20px;
    margin-right: 10px;
}

.dialog .radio_icon.active {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.dialog .button_div .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dialog .button_div .buttons .generate_code {
    margin-top: 20px;
}

.authenticate_dialog > div > div {
    width: 800px;
    max-width: 800px;
}

.dialog.authenticate_dialog .content {
    padding: 50px 40px;
}

.authenticate_dialog .title_text {
    font-family: Roboto, sans-serif;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
    margin-bottom: 20px;
}

.authenticate_dialog a {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #3E8EF7;
    text-decoration: unset;
    margin: 0 20px;
}

.authenticate_dialog a:hover {
    text-decoration: underline;
}

.authenticate_dialog .links_div {
    margin-bottom: 40px;
}

.authenticate_dialog .links_div > span {
    width: 1px;
    background: #b0b0b0;
    height: 10px;
    display: inline-block;
}

.authenticate_dialog .qr_code {
    width: 200px;
    margin-left: 100px;
}

.authenticate_dialog .qr_info {
    font-family: Roboto, sans-serif;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
    margin-top: 10px;
}

.authenticate_dialog .qr_info b {
    color: #3E8EF7;
    font-weight: normal;
}

.authenticate_dialog .note_text {
    font-family: Roboto, sans-serif;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    margin-bottom: 40px;
}

.authenticate_dialog .text_field {
    margin: 0 0 0 20px;
    width: 50%;
}

.authenticate_dialog .verify_button {
    background: #EF0048;
    border-radius: 3px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    text-transform: unset;
}

.authenticate_dialog .verify_button:hover {
    background: #EF0048;
}

.authenticate_dialog .verify_button[disabled] {
    cursor: no-drop;
    opacity: 0.4;
    color: #fff;
}

.success_dialog .success_text {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.success_dialog .success_text > p {
    font-family: Roboto, sans-serif;
    font-size: 30px;
    line-height: 35px;
    color: #00BE51;
    margin-left: 50px;
}

.success_dialog .note_div .text {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #696969;
}

.success_dialog .note_div .text b {
    font-weight: normal;
    text-decoration: underline;
}

.success_dialog .download_button {
    background: #00BE51;
    border-radius: 3px;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #FFFFFF;
    padding: 0;
    text-transform: unset;
    flex-shrink: 0;
}

.success_dialog .download_button:hover {
    background: #049944;
}

.success_dialog .download_button a {
    font-family: Roboto, sans-serif;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #FFFFFF;
    margin: unset;
    padding: 8px 10px;
    text-decoration: unset;
}

.success_dialog .note_div {
    align-items: center;
}

.success_dialog .no_data {
    flex-shrink: 0;
    background: #FFF3CD;
    padding: 10px;
    color: #8D6404;
    font-weight: bold;
    margin-left: 10px;
}

.authenticate_dialog.success_dialog .note_text {
    font-family: Roboto, sans-serif;
    font-size: 22px;
    line-height: 140%;
    color: #696969;
}

.error_dialog .error_text {
    font-family: Roboto, sans-serif;
    font-size: 22px;
    line-height: 26px;
    color: #D30505;
}

.authenticate_dialog .letter_spacing input {
    letter-spacing: 40px;
}

@media (max-width: 769px) {
    .authenticate_dialog > div > div {
        width: 500px;
    }

    .dialog.authenticate_dialog .content {
        padding: 20px 30px;
    }

    .authenticate_dialog .qr_code {
        width: 150px;
        margin-left: 50%;
        transform: translateX(-75px);
    }

    .authenticate_dialog .text_field {
        width: 90%;
    }

    .error_dialog .error_text,
    .authenticate_dialog.success_dialog .note_text {
        font-size: 20px;
    }

    .success_dialog .success_text > img {
        width: 50px;
    }

    .success_dialog .success_text > p {
        font-size: 22px;
        margin-left: 30px;
    }

    .success_dialog .success_text {
        margin-bottom: 20px;
    }
}

@media (max-width: 426px) {
    .authenticate_dialog .title_text,
    .authenticate_dialog .qr_info {
        font-size: 16px;
    }

    .authenticate_dialog .note_text {
        font-size: 14px;
    }

    .authenticate_dialog .text_field {
        width: 80%;
    }

    .dialog.authenticate_dialog .content {
        padding: 20px;
    }

    .error_dialog .error_text {
        font-size: 18px;
    }

    .authenticate_dialog.success_dialog .note_text {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .success_dialog .success_text > p {
        margin-left: 20px;
    }
}

@media (max-width: 376px) {
    .authenticate_dialog a {
        margin: 0 10px;
        font-size: 14px;
    }
}
