.sign_in {
    background: #003B42;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
}

.sign_in .card {
    padding: 0 60px;
    color: #76838f;
    background: #fff;
    width: 450px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
}

.sign_in .card .heading {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.2;
    color: #37474f;
    text-shadow: rgba(0, 0, 0, .15) 0 0 1px;
    margin-top: 22px;
    margin-bottom: 50px;
}

.sign_in .check_box > span:last-child {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.571429;
    color: #76838f;
}

.sign_in .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sign_in a {
    margin-bottom: 4px;
    color: #003B42;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.571429;
}

.sign_in a:hover {
    text-decoration: underline;
}

.sign_in .sign_in_button {
    color: #fff;
    background-color: #003B42;
    box-shadow: none;
    width: 100%;
    margin-top: 38px;
    font-weight: 300;
    text-align: center;
    padding: .429rem 1rem;
    font-size: 1rem;
    line-height: 1.571429;
    text-transform: unset;
    border-radius: .215rem;
}

.sign_in .sign_in_button:hover {
    color: #fff;
    background-color: #003B42;
    box-shadow: none;
}

.sign_in .flex_row {
    display: flex;
    margin-top: 20px;
}

.sign_in .flex_row > p {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.571429;
    color: #76838f;
}

.sign_in .info {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.sign_in .info .text {
    font-family: 'Roboto', sans-serif;
    font-size: 45px;
    line-height: 58px;
    letter-spacing: 0.307989px;
    color: #FFFFFF;
    margin-top: 30px;
    text-align: center;
}

.signup .check_box > span:first-child {
    padding: 2px 9px;
}

.check_box p,
.check_box span {
    color: #fff;
    padding-top: 3px;
    font-weight: 600;
    opacity: 0.7;
    border: unset;
}

.check_box p a {
    color: #7165E3;
}

.backlink__container {
    width: 120px;
}

.backlink svg {
    width: 60px;
}

@media (max-width: 767px) {
    .backlink__container {
        width: 90px;
    }

    .backlink svg {
        width: 40px;
    }
}

@media (max-width: 426px) {
    .sign_in .card {
        padding: 0 40px;
    }

    .sign_in .card .heading {
        margin-bottom: 40px;
        text-align: center;
    }

    .sign_in .info {
        display: none;
    }
}

@media (max-width: 321px) {
    .sign_in .card {
        padding: 0 20px;
    }
}
