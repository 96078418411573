.dialog.close_deal_dialog .content {
    width: 600px;
    padding: 40px 50px 20px;
}

.dialog.close_deal_dialog .content .row {
    align-items: center;
    justify-content: unset;
}

/*
.close_deal_dialog .button_div .close_submit {
    background: #003B42;
    border-radius: 5px;
    font-family: Roboto,sans-serif;
    font-size: 16px;
    line-height: 23px;
    color: #FFFFFF;
    padding: 6px 16px;
    text-transform: unset;
} */

.close_deal_dialog .button_div .cancel_button {
    padding: 15px 20px;
    font-size: 16px;
    border-radius: 6px;
}

.close_deal_dialog .button_div .close_submit {
    border-radius: 5px;
    padding: 15px 20px;
    color: #FFFFFF;
    background-color: #4CBDC5;
    font-size: 16px;
    border-radius: 6px;
}

@media screen and (max-width: 769px) {
    .dialog.close_deal_dialog .content {
        max-width: 600px;
        width: 100%;
        padding: 40px 50px 20px;
    }

    .close_deal_dialog .button_div .cancel_button,
    .close_deal_dialog .button_div .close_submit {
        padding: 15px 20px;
        font-size: 12px;
        border-radius: 6px;
    }
}
