.dialog.deal_dialog .content {
    width: 1080px;
    padding: 40px 50px;
}

.dialog.deal_dialog .content .row {
    align-items: center;
    justify-content: unset;
}

.deal_dialog .row .heading_text {
    width: 34%;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    line-height: 23px;
    color: #696969;
}

.deal_dialog .row .heading_text .required {
    color: rgb(218, 63, 63);
}

.deal_dialog .row .text_field {
    margin: 0;
    width: 60%;
}

.deal_dialog .row1 .text_field {
    width: 40%;
}

.deal_dialog .row .helper_text {
    margin-left: 20px;
    width: 22%;
    display: flex;
    flex-direction: column;
}

.deal_dialog .form {
    margin-top: 20px;
}

.deal_dialog .helper_text .text1 {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #3E8EF7;
    text-transform: unset;
}

.deal_dialog .helper_text .text2 {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #696969;
    text-align: center;
}

.deal_dialog .spinner > div {
    background: #696969;
}

.deal_dialog .select_field {
    width: 100%;
}
.deal_dialog .select_field > div {
    padding: 15px 15px;
}
.deal_dialog svg {
    color: #fff;
}

.deal_dialog .cancel_button {
    background-color: transparent;
    border: unset;
    box-shadow: none;
}

div.deals_button_div {
    padding: 3% 0;
    justify-content: end;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    color: #fff;
    filter: brightness(0) invert(1);
}

/* .tier-limit.MuiFormControl-marginNormal {
    margin: 0;
} */

.deal_dialog .button_div .submit_new {
    border-radius: 5px;
    padding: 15px 20px;
    color: #FFFFFF;
    background-color: #4CBDC5;
    font-size: 16px;
    border-radius: 6px;
}

.deal_dialog .button_div .stake_button[disabled] {
    cursor: no-drop;
    opacity: 0.4;
}

.deal_dialog .text_area {
    height: 14vh;
    min-height: 179px;
}

@media (max-width: 769px) {
    .dialog.deal_dialog .content {
        width: 100%;
    }
    .MuiButton-root.view_details_button {
        margin: 20px 0;
    }
    .profile-page__deals .MuiTable-root tr.MuiTableRow-root {
        border-top: solid 2px rgba(255, 255, 255, 0.15);
        border-bottom: solid 2px rgba(255, 255, 255, 0.15);
    }

    .profile-page__deals  tfoot .MuiTableRow-footer .MuiTableCell-footer {
        border: none;
    }
    .profile-page__deals .MuiTableFooter-root  tr.MuiTableRow-root {
        border: none;
    }
}

@media (max-width: 426px) {
    .dialog.deal_dialog .content {
        padding: 20px 5%;
        width: 100%;
        max-width: 100%;
    }

    .dialog.deal_dialog .content .row {
        flex-direction: column;
        align-items: center;
    }

    .deal_dialog .row1 .text_field {
        margin-top: 16px;
        margin-bottom: 8px;
    }

    .deal_dialog .row .heading_text,
    .deal_dialog .row1 .text_field,
    .deal_dialog .row .helper_text,
    .deal_dialog .select_field {
        width: 100%;
    }
}
