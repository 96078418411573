html, body, #root, .ex_network, .main_content, .content_div, .side_bar {
    min-height: 100% !important;
    height: auto;
}

body {
    overflow: auto;
}

.content_div > div {
    height: auto;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    text-align: left;
    background: #0C0F0F;
    color: #fff;
}

p {
    margin: unset;
}

.nav_padding {
    padding: 0 20px;
}

.content_padding {
    padding: 30px 50px;
}

.nav_bar + div {
    padding-top: 80px;
}

.scroll_bar {
    overflow: auto;
}

/* scroll bar */
html::-webkit-scrollbar,
.scroll_bar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 4px;
}

html::-webkit-scrollbar-thumb,
.scroll_bar::-webkit-scrollbar-thumb {
    border: 0 solid #fff;
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, .24);
    border-radius: 20px;
    background: #fff;
}

@media (max-width: 1025px) {
    .nav_bar + div {
        padding-top: 90px;
    }
}

@media (max-width: 769px) {
    body {
        overflow: auto;
    }

    .scroll_bar {
        overflow: unset;
    }
}

@media (max-width: 426px) {
    .content_padding {
        padding: 50px 20px;
    }
}
