.notification {
    svg {
        width: 100%;
    }

    .circle {
        background-color: #D7B367 !important;
        color: #fff !important;
        font-size: 12px !important;
        width: auto !important;
        height: auto !important;
        display: inline-block !important;
        line-height: 1.3 !important;
        right: auto !important;
        left: calc(100% - 12px);

        span {
            padding: 15px 5px 12px;
        }
    }

    &__tail {
        width: 30px;
        height: 30px;
        border-radius: 10px;
        background-color: #181F20;
        transform: rotate(45deg);
        display: inline-block;;

        @media all and (max-width: 1023px) {
            // display: none;
            margin-left: calc(100% - 185px);
        }

        @media all and (max-width: 640px) {
            margin-left: calc(100% - 185px);
        }

    }

    &__container {
        position: absolute;
        top: 90%;
        width: 300px;
        left: -106px;
        text-align: center;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.4s ease, visibility 0s ease 0.4s;
        margin-top: 30px;

        &.active {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.4s ease, visibility 0s ease;
        }

        @media all and (max-width: 1023px) {
            left: -235px;
            top: auto;
            
            .right_content & {
                display: none;
            }
        }

        @media all and (max-width: 640px) {
            left: 0;
            right: 0;
            width: 100vw;
        }
    }

    &__wrapper {
        margin-top: -25px;
        box-shadow: 1px 2px 16px rgba(0, 0, 0, 0.14);
        border-radius: 5px;
        background: #111617;

        // @media all and (max-width: 1023px) {
        //     margin-top: 0;
        // }
    }

    &__header {
        padding: 20px 5% 10px;
    }

    &__read {
        position: relative;
        font-family: Oswald;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 19px; /* 135.714% */
        letter-spacing: -0.23px;
        opacity: 1 !important;

        &.inactive {
            color: #f1eeef !important;
        }

        &.active {
            color: #EF0048 !important;
        }
    }

    &__read_filter {
        position: absolute;
        width: 35px;
        height: 25px;
        flex-shrink: 0;

        border-radius: 12.5px;
        opacity: 0.10000000149011612;
        background: #EF0149;
    }

    &__unread {
        color: #FFF;
        font-family: Oswald;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 19px; /* 135.714% */
        letter-spacing: -0.23px;
    }

    &__num {
        background-color: #EF0048;
        color: #fff;
        height: 32px;
        padding: 5px 10px 3px;
        line-height: 1.3;
        border-radius: 32px;
    }

    &__notif-header {
        border-bottom: 1px solid rgba(0,0,0,0.2);
        padding: 0 5%;
        @apply py-2 font-semibold font-oswald-text;
        span {
            @apply opacity-30;
        }
    }

    &__notif-image {
        width: 55px;
        height: 55px;
        border-radius: 100%;
        border-color: #f1eeef;
        overflow: hidden;
        flex-shrink: 0;
        img {
            width: 55px;
            height: 55px;
            object-fit: cover;
        }
    }

    &__body {
        max-height: 385px;
        overflow: auto;

        @media all and (max-width: 640px) {
            max-height: 360px;
        }
    }

    &__notif-item {
        padding: 0 8%;
        @apply py-2 flex items-center;

        &.showAllNotifs {
            background-color: #494949;
        }
    }

    &__notif-detail {
        padding-left: 10px;
        padding-right: 5%;
        p {
            // color: #fff;
            // @apply font-sfpro-text text-sm;
            color: #FFF;
            font-family: Oswald;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 19px; /* 118.75% */
            letter-spacing: -0.23px;
        }

        &.time_from_now {
            color: #FFF;
            font-family: Oswald;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 19px; /* 135.714% */
            letter-spacing: -0.23px;
            opacity: 0.5;
            padding-left: 0 !important;
        }
    }

    &__notif-name {
        color: #000;
        @apply font-semibold opacity-100 font-oswald-text;
    }
}

::-webkit-scrollbar,
.scroll_bar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb,
.scroll_bar::-webkit-scrollbar-thumb {
    border: 0 solid #fff;
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, .24);
    border-radius: 20px;
    background: #fff;
}