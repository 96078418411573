.deal_details .card {
    background: #FFFFFF;
    border-radius: 5px;
    margin: 30px 0;
    padding: 50px 40px;
}

.deal_details .row {
    display: flex;
}

.deal_details .row.align_center {
    align-items: center;
}

.deal_details .row .heading {
    font-family: Roboto,sans-serif;
    font-size: 20px;
    line-height: 23px;
    color: #696969;
    width: 40%;
}

.deal_details .row .id_text {
    color: #3E8EF7;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}

.deal_details .row a.id_text {
    text-decoration: unset;
}

.deal_details .row a.id_text:hover {
    text-decoration: underline;
}

.deal_details .row .value {
    font-family: Roboto,sans-serif;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
}

.deal_details .row .text_area {
    background: #F5F5F5;
    border-radius: 5px;
    padding: 20px 30px;
    font-family: Roboto,sans-serif;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    word-break: break-word;
    width: 60%;
}

.deal_details .card .edit_deal,
.deal_details .card .add_distribution_detail,
.deal_details .card .reopen_deal {
    background: #003B42;
    border-radius: 5px;
    font-size: 16px;
    line-height: 1.2;
    padding: 6px 16px;
    text-transform: unset;
}

.deal_details .card .close_deal {
    background: #f50057;
    border-radius: 5px;
    font-size: 16px;
    line-height: 1.2;
    padding: 6px 16px;
    text-transform: unset;
}

.deal_details__header {
    border-color: rgba(0,0,0,0.05);
}

.MuiButton-root.close_deal,
.MuiButton-root.archive_deal,
.MuiButton-root.edit_deal,
.MuiButton-root.add_distribution_detail,
.MuiButton-root.reopen_deal {
    color: #fff;
    font-family: 'Oswald';
    text-transform: none;
    padding: 15px 25px;
    border-radius: 8px;
}

.MuiButton-root.edit_deal,
.MuiButton-root.add_distribution_detail,
.MuiButton-root.reopen_deal {
    background-color: #4CBDC5;
}

.MuiButton-root.archive_deal {
    background-color: #F20055;
}

.MuiButton-root.close_deal {
    background-color: #F20055;
}
.icon-dark-back-arrow {
    width: 15px;
}

xmp {
    white-space: pre-wrap;
}

.MuiTooltip-popper {
    width: 250px;
    text-align: center;
}

.MuiTooltip-tooltip {
    font-size: 1em !important;
}

.deal-detail__link:hover {
    text-decoration: underline;
}

.archived-text {
    color: red !important;
}

.deals-detail .profile-page__deals .MuiButtonBase-root.view_details_button {
    background-color: #EF0048;
    color: #fff;
    padding: 10px 20px;
}
table tbody tr > td.MuiTableCell-root {
    border: unset;
    font-size: 1rem;
    font-family: 'Oswald';
}