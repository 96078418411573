.content_div .dashboard {
  height: auto;
}

.dashboard .cards {
  display: flex;
  justify-content: center;
}

.dashboard .card {
  border-radius: 0.215rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  padding: 40px 30px;
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(140.33deg, #003b42 -5.79%, #20939c 120.14%);
}

.dashboard .card .value {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 40px;
  line-height: 131%;
  text-align: center;
  letter-spacing: 0.0015em;
  color: #ffffff;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.card .name {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 24px;
  line-height: 131%;
  text-align: center;
  letter-spacing: 0.0015em;
  text-transform: capitalize;
  color: #ffffff;
}

.dashboard .card:last-child {
  flex-direction: row;
  justify-content: space-around;
}

.card .progress_bar .circular_progress {
  color: #ffffff;
}

.card .progress_bar .text {
  font-family: Roboto, sans-serif;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.0015em;
  color: #ffffff;
}

.card .progress_bar .dummy_progress {
  position: absolute;
  color: #ffffff14;
}

.dashboard .table {
  margin: 50px 0;
  margin-top: 2rem;
  position: relative;
  display: block;
}

.table .heading {
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 1;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}

.dashboard table thead th:nth-child(1) > span,
.dashboard table thead th:nth-child(2) > span,
.dashboard table thead th:nth-child(3) > span,
.dashboard table thead th:nth-child(4) > span,
.dashboard table thead th:nth-child(5) > span,
.dashboard table thead th:nth-child(6) > span,
.dashboard table thead th:nth-child(7) > span,
.dashboard table thead th:nth-child(8) > span {
  justify-content: start;
  margin-top: 5px;
}

.dashboard table thead th:nth-child(1) > div,
.dashboard table thead th:nth-child(2) > div,
.dashboard table thead th:nth-child(3) > div,
.dashboard table thead th:nth-child(4) > div,
.dashboard table thead th:nth-child(5) > div,
.dashboard table thead th:nth-child(6) > div,
.dashboard table thead th:nth-child(7) > div,
.dashboard table thead th:nth-child(8) > div {
  text-align: left;
  margin-top: 5px;
}

.dashboard table tbody td:nth-child(1) div,
.dashboard table tbody td:nth-child(2) div,
.dashboard table tbody td:nth-child(3) div,
.dashboard table tbody td:nth-child(4) div,
.dashboard table tbody td:nth-child(5) div,
.dashboard table tbody td:nth-child(6) div,
.dashboard table tbody td:nth-child(7) div,
.dashboard table tbody td:nth-child(8) div {
  text-align: left;
  margin: auto;
  margin-top: 5px;
}

.dashboard table tbody .hash_text {
  display: flex;
  cursor: pointer;
}

.dashboard table tbody .ellipses_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80px;
}

.dashboard table tbody td[colspan="6"]:first-child div,
.dashboard table tbody td[colspan="8"]:first-child div,
.dashboard table tbody td[colspan="4"]:first-child div,
.dashboard table tbody td[colspan="5"]:first-child div {
  width: 100%;
  max-width: 100%;
}

/* .dashboard table tbody td:nth-child(2) div,
.dashboard table tbody td:nth-child(3) div {
    text-transform: capitalize;
} */

.dashboard .table .donation_type {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard .table .donation_type .icon {
  width: 24px;
  margin-right: 10px;
}

.dashboard .table .donation_type p {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.dashboard .table .transaction_type {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard .table .transaction_type p {
  font-family: "Oswald";
  font-size: 18px;
  line-height: 21px;
}

.dashboard .table .transaction_type .icon {
  width: 14px;
  margin-left: 10px;
}

.dashboard .table .red {
  color: #ff0000;
}

.dashboard .table .green {
  color: #00be51;
}

.dashboard .table .warning {
  color: #ff7a00;
}

.dashboard .table .status {
  font-family: "Oswald";
  font-size: 18px;
  line-height: 21px;
}

.dashboard .table .view_details_button {
  background: #111617;
  border-radius: 5px;
  font-family: "Oswald";
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  border: unset;
  text-transform: unset;
  padding: 8px 18px;
}

.dashboard .table .view_all {
  border-radius: 5px;
  font-family: "Oswald";
  font-size: 16px;
  line-height: 23px;
  color: #7163c7;
  padding: 5px 20px;
  text-transform: unset;
}

.dashboard .table .view_all {
  background-color: #ef0048;
}

.dashboard .table .view_all[disabled] {
  cursor: no-drop;
  background: #003c4266;
  z-index: 101;
}

.dashboard .card .left_content {
  text-align: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #ffffff;
}

@media (max-width: 1025px) {
  .dashboard .cards {
    flex-wrap: wrap;
  }

  .dashboard .card {
    padding: 30px 20px;
  }

  .dashboard .card .value {
    font-size: 34px;
  }

  .card .name {
    font-size: 20px;
  }

  .graph_card .buttons > button {
    margin: 0;
  }
}

@media (max-width: 769px) {
  .dashboard .cards {
    flex-wrap: wrap;
  }

  .dashboard .card {
    width: 48%;
    margin-bottom: 20px;
  }

  .dashboard .card:nth-child(3) {
    width: 100%;
    margin: unset;
  }

  .dashboard .table {
    margin-top: 20px;
    display: block;
  }
}

@media (max-width: 426px) {
  .main_dashboard .table > div > div[role="toolbar"] {
    flex-direction: column;
  }

  .main_dashboard .table div[role="toolbar"] > div:first-child {
    width: 100%;
  }

  .dashboard .card {
    width: 100%;
  }
}
