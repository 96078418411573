.info {
  // background: linear-gradient(45deg, #00a0ad 85%, transparent 120%);
  // background: -webkit-linear-gradient(45deg, #00a0ad 85%, transparent 120%);
  font-family: Proxima Nova;

  &__content {
    padding: 50px 10% 40px;

    h1 {
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  &__image {
    &:before {
        content: '';
        background: linear-gradient(180deg, #111617 0%, rgba(0, 0, 0, 0) 100%);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}
  @media all and (max-width: 1023px) {
    background: #fff;
  }
}
