.maturity-date > div {
    padding: 10px 15px;
    font-size: 1rem;
    box-sizing: border-box;
    align-items: center;
    font-weight: 400;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
    color: #fff;
}

.maturity-date > div:before,
.maturity-date > div:after {
    display: none;
}