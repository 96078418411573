.circular_progress {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circular_progress > div {
    color: #3E8EF7;
}
