body {
    padding: 0 !important;
    overflow: auto !important;
}

.tasks .table > div > div[role='toolbar'] {
    display: none;
}

.tasks .table {
    width: 100%;
}

.tasks .table > div {
    padding: 0;
}

.tasks .table .MuiTableCell-root {
    border-bottom: 0;
    background: #111617;
}

.tasks {
    min-height: 100vh !important;
}

.tasks .circle {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50px;
}

.tasks-dialog {
    padding: 50px 30px 20px !important;
    min-width: 500px;
}

.tasks-dialog .header {
    border-bottom: 1px solid rgba(230, 232, 235, 0.2);
}

.tasks-dialog .circle {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50px;
}

.circle--blue {
    background-color: #55C8FF;
}

.circle--yellow {
    background-color: #FDC145;
}

.circle--red {
    background-color: #EF0048;
}

.tasks-button {
    background-color: transparent !important;
    color: #fff !important;
    padding: 10px 25px !important;
    text-transform: none !important;
    border: 1px solid #858787 !important;
}

.tasks-button--create {
    padding: 6px 35px !important;
}

.tab_content .MuiBox-root-6 {
    padding: 24px 0;
}

.task-select {
    border-radius: 6px;
    color: #fff;
    background-color: #EF0048;
    font-family: 'Oswald';
    padding: 5px 20px;
    min-width: 130px;
}

.task-select .MuiSelect-select {
    color: #fff;
}

.task-select .MuiSelect-select .MuiSelect-iconOpen {
    color: #fff;
}

.task-select:before,
.task-select:after {
    display: none;
}

.linkbox {
    background-color: #F5F6FA;
    border-radius: 5.5px;
}

.linkbox a {
    color: #428CEB;
    border-bottom: 1px solid #428CEB;
}

.upload-item {
    width: 97px;
    height: 97px;
    background: #F5F6FA;
    border-radius: 5.5px;
    overflow: hidden;
}

.button-upload,
.button-cancel {
    background-color: #EF0048 !important;
    color: #fff !important;
    text-transform: none !important;
    font-size: 16px !important;
    line-height: 19px !important;
    letter-spacing: 0.1px !important;
    padding: 20px !important;
    border-radius: 8px !important;
    margin-bottom: 10px !important;
    font-family: 'Oswald' !important;
    font-weight: 600 !important;
}

.button-cancel {
    background-color: transparent !important;
    color: #808080 !important;
}

.tasks-actions {
    padding: 0 30px !important;
}

.task-action__container {
    min-width: 150px;
}

.task-action__options {
    width: 100%;
    left: 0;
    top: 100%;
    background: #FFFFFF;
    border: 0.5px solid #E6E8EB;
    box-shadow: 0px 4px 10px 3px rgb(0 0 0 / 6%);
    border-radius: 6px;
    padding: 15px 0;
    z-index: 1;
    text-align: left;
}

.task-action__options span {
    padding: 5px 10px;
    font-family: 'Oswald';
    color: rgba(0,0,0,0.5);
    font-weight: 600;
    cursor: pointer;
}

.task-action__options span:hover {
    background-color: rgba(0,0,0,0.05);
}

.task-close {
    cursor: pointer;
}

.button-twitter,
.button-okay,
.button-tryagain {
    background-color: #EF0048 !important;
    color: #fff !important;
    text-transform: none !important;
    font-size: 16px !important;
    line-height: 19px !important;
    font-family: 'Oswald';
    letter-spacing: 0.1px !important;
    padding: 20px !important;
    border-radius: 8px !important;
    margin-bottom: 10px !important;
    margin-left: 0 !important;
}

.button-tryagain,
.button-okay {
    background-color: #EF0048 !important;
    font-family: 'Oswald' !important;
}

.MuiDialog-paper.MuiPaper-root {
    background-color: #111617;
}
.linkbox {
    background-color: #08090A;
}
.upload-item {
    background-color: rgba(245, 246, 250, 0.1);
}

.participant-item {
    width: 54px;
    height: 54px;
    background-color: #fff;
    border-radius: 54px;
    overflow: hidden;
    margin-left: -12.5px;
}

.participant-item.item-0 {
    z-index: 3;
}

.participant-item.item-1 {
    z-index: 2;
}

.participant-item.item-2 {
    z-index: 1;
}

.participant-item.item-3 {
    z-index: 0;
}

.participant-item.item-3 div {
    background-color: #F2F2F2;
    width: 54px;
    height: 54px;
    border-radius: 54px;
    
}

.participant-item.item-3 span {
    color: #808080;
}

.participant-item:first-child {
    margin-left: 0;
}

.participant-item > div {
    padding: 4px;
}

.tasks .table tr th:last-child,
.tasks .table tr td:last-child {
    text-align: right;
}

.tasks .table--archive tr th:last-child,
.tasks .table--archive tr td:last-child {
    text-align: left;
}


.task-radio span {
    border: none;
    font-size: 14px;
}

.task-radio > span:first-child {
    color: #808080;
}

.task-radio span.Mui-checked:first-child {
    color: #00A0AD;
}

.task-dialog__field .text_field,
.task-dialog__field .text_area {
    margin-top: 10px;
    width: 100%;
}

.task-dialog__field textarea,
.task-dialog__field input {
    font-size: 14px !important;
    width: 100%;
}

.notask {
    width: 320px;
    margin: 0 auto;
}

.notask img {
    max-width: 100px;;
}

.task-dialog__imagebox {
    width: 60px;
    height: 60px;
    border-radius: 60px;
    overflow: hidden;
}

.tab_content--no-border {
    border-top: 0;
}

.task-dialog__btn .view_details_button {
    background: #fff;
    border-radius: 5px;
    font-family: 'Oswald';
    font-size: 14px;
    line-height: 16px;
    color: #696969;
    border: 1px solid #00A0AD;
    text-transform: unset;
    padding: 8px 18px;
    width: 125px;
}

.tabs_section .table > div > div:nth-child(3) {
    overflow-x: visible !important;
    overflow-y: visible !important;
}

@media screen and (max-width: 1023px) {
    .tasks .table tr th:last-child,
    .tasks .table tr td:last-child {
        text-align: left;
    }

    .task-action__options {
        width: auto;
    }
    .tasks .circle {
        margin-top: 10px
    }
}

@media screen and (max-width: 767px) {
    .button-upload,
    .button-cancel,
    .button-twitter,
    .button-okay,
    .button-tryagain {
        padding: 15px !important;
        font-size: 12px !important;
        line-height: 15px !important;
    }

    .tasks-dialog {
        padding: 30px 30px 10px !important;
        min-width: 0;
    }

    div.MuiBox-root {
        padding: 24px 0;
    }
}
