.unstake_request .cards .card {
    width: 23%;
    position: relative;
}

.unstake_request .card .filter {
    position: absolute;
    right: 10px;
    top: 0;
    color: #fff;
}

.MuiIconButton-root.filter {
    position: absolute;
}