.users .cards .card {
    width: 48%;
    position: relative;
}

.users .card .filter {
    position: absolute;
    right: 10px;
    top: 0;
    color: #fff;
}

.users table tbody tr td:last-child > div:last-child {
    max-width: 200px;
}

.MuiIconButton-root.filter {
    position: absolute;
    z-index: 100;
}

.user-detail__link:hover {
    text-decoration: underline;
}