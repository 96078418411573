.next-vault table .view_details_button {
    font-size: 14px;
    font-family: 'Oswald';
    letter-spacing: -0.23px;
    line-height: 20px;
    border-radius: 5px;
    border: 1px solid #858787;
    text-transform: unset;
    padding: 8px 18px;
    background-color: #0C0F0F;
    color: #FFF;
}

.next-vault svg {
    color: #fff;
}

.next-vault .new_deal {
    background: #EF0048;
    border-radius: 5px;
    font-family: 'Oswald';
    font-size: 16px;
    line-height: 1;
    color: #FFFFFF;
    padding: 15px 20px;
    text-transform: unset;
    margin-left: 10px;
}

.dialog.next-vault .content,
.modal-footer {
    width: 500px;
    padding: 38px 50px 10px;
}

.dialog.next-vault-log-modal .MuiDialogActions-root {
    padding: 0;
}

.dialog.next-vault-log-modal .content {
    width: 500px;
    padding: 40px 50px 20px;
}

.dialog.next-vault-log-modal .modal-footer {
    width: 500px;
    padding: 20px 50px 40px;
}

.next-vault-log__date .date-picker {
    height: 3.2rem;
    font-size: .8rem;
}

@media (max-width: 769px) {
    .dialog.next-vault .content {
        width: 100%;
    }
}

@media (max-width: 426px) {
    .dialog.next-vault .content {
        padding: 20px 5%;
        width: 100%;
        max-width: 100%;
    }
}