.accordion__column .icon {
  max-width: 25px;
}

.forum-editor .rdw-editor-toolbar {
  background-color: #111617;
  border-color: rgba(255, 255, 255, 0.5);
}
.forum-editor .rdw-option-wrapper {
  background-color: #EF0048;
  border: 1px solid #EF0048;
  color: #fff;
}

.forum-editor .rdw-dropdown-wrapper,
.forum-editor .rdw-dropdown-optionwrapper {
  background-color: #EF0048;
  border: 1px solid #EF0048;
  color: #fff;
}

.forum-editor .rdw-dropdownoption-active,
.forum-editor .rdw-dropdownoption-highlighted  {
  color: #EF0048;
}

.accordion__header.MuiAccordionSummary-root {
  background-color: #EF0048;
  border-radius: 10px;
  transition: border-radius 0.4s ease-in-out;
}

.accordion__header .MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0;
}

.MuiPaper-root.MuiAccordion-root {
  background: transparent;
}

.accordion__row:last-child {
  border-bottom-width: 0;
}

.accordion.MuiAccordionDetails-root {
  padding: 8px 0 0;
  border-radius: 0 0 10px 10px;
}

.accordion__header.MuiAccordionSummary-root.Mui-expanded {
  border-radius: 10px 10px 0 0;
  transition: border-radius 0.4s ease-in-out;
}