.edit-profile {
    background: #fff;
}
div.edit-profile {
    height: auto;
    min-height: calc(100vh - 80px);
}
.edit-profile__form {
    max-width: 425px;
    margin: 0 auto;
}
.edit-profile__photo img {
    width: 200px;
    object-fit: cover;
    position: relative;
    height: 200px;
}
.edit-profile__form .btn__cancel {
    background: transparent;
    color: #00A0AD;
    padding: 14px 56px;
}
.edit-profile__form .btn__save {
    width: 149px;
    color: #fff;
    background: #00A0AD;
    border-radius: 8px;
    padding: 14px 56px;
}
.edit-profile__form .btn__save:hover {
    color: #00A0AD;
}
.upload-btn .icon-camera {
    width: 20px;
    fill: #4A90E2;
}
.edit-profile__form .upload-btn:hover {
    background-color: transparent;
}
.edit-profile__form label {
    font-size: 16px;
    font-family: 'Oswald';
    color: #4E587A;
}

.edit-profile__photobox {
    height: 200px;
    width: 200px;
    border-radius: 100px;
    overflow: hidden;
}

@media (max-width: 767px) {
    div.edit-profile {
        min-height: calc(100vh - 70px);
    }
}