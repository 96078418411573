.profile-page {
    background: #F5F6FA;
}
.profile-page__header {
    z-index: 1;
}
.profile-page .profile-page__banner,
.public-profile .profile-page__banner  {
    width: 100%;
    min-height: 300px;
    background-color: black;
    position: relative;
}
.public-profile .profile-page__banner {
    z-index: -1;
}
.profile-page .profile-page__banner img,
.public-profile .profile-page__banner img {
    width: 100%;
    object-fit: cover;
    position: relative;
    height: 300px;
}
.profile-page .profile-page__body,
.public-profile .public-profile__body {
    max-width: 1079px;
    margin: 0 auto;
}
.profile-page .btn__banner,
.public-profile .btn__banner {
    background: rgba(245, 246, 250, 0.75);
    max-width: 192px;
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Oswald';
    text-transform: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #5F6786;
    padding: 18px 16px;
    position: absolute;
    right: 129px;
    bottom: 24px;
}
.public-profile .btn__banner {
    top: 30px;
    bottom: auto;
}
.profile-page .icon-camera,
.public-profile .icon-camera {
    width: 24px;
}
.profile-page__bio {
    display: flex;
    margin-top: -100px;
    z-index: 12;
}
.profile-page .btn__bio {
    font-weight: 600;
    text-transform: none;
    max-width: 149px;
    padding: 16px 30px;
    border-radius: 8px;
    margin-top: 37px;
}

.profile-page .bio__image {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 100%;
    background-color: #fff;
}
.bio__photo {
    z-index: 2;
}
.bio__details {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin-left: 20px;
    z-index: 2;
}
.bio__name {
    font-family: 'Oswald';
    font-size: 34px;
    font-weight: 900;
    color: #F5F6FA;
}
.bio__email {
    color: #F5F6FA;
    font-size: 14px;
    font-family: 'Oswald';
}
.public-profile__bio {
    background: #fff;
    max-width: 1079px;
    margin: 0 auto;
    border-radius: 10px;
}
.public-bio__name {
    color: #1F243B;
    font-size: 34px;
    line-height: 41px;
    font-family: 'Oswald';
}
@media (max-width: 769px) {
    .profile-page .profile-page__body,
    .public-profile .public-profile__body {
        max-width: 100%;
    }
    .profile-page__newsfeed .newsfeed__tips,
    .profile-page__newsfeed .newsfeed__info {
        width: 100%;
    }
    .profile-page .btn__banner {
        top: 30px;
        right: 10px;
        bottom: auto;
    }
}

@media (max-width: 767px) {
    .bio__details {
        justify-content: center;
        align-items: center;
        margin-left: 0;
    }

    .bio__name,
    .bio__email {
        color: #fff;
    }
}