@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Proxima+Nova:wght@400;800');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;600;700;800&display=swap');

@font-face {
  font-family: 'SF Pro Text';
  src: url('../fonts/SFProText/SFProText-Regular.woff2') format('woff2'),
  url('../fonts/SFProText/SFProText-Regular.woff') format('woff'),
  url('../fonts//SFProText/SFProText-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.text_field fieldset {
  border: none;
}

.text_field input {
  padding: 1rem 1rem;
  font-size: 0.8rem;
}

.card .MuiFormControl-marginNormal {
  margin-top: 10px;
  margin-bottom: 20px;
}

.text_field > div {
  height: auto;
}

.check_box .MuiTypography-body1 {
  font-family: 'Oswald';
  font-size: 0.9rem;
}

.check_box .MuiSvgIcon-root {
  color: #4a90e2;
}

.MuiFormControl-marginNormal {
  margin-top: 10px;
  margin-bottom: 20px;
}

.MuiButton-root.sign_in_button,
.MuiButton-root.reset_button,
.MuiButton-root.generate_code {
  padding: 20px 10px;
  background-color: #EF0048;
  color: #fff !important;
  font-family: 'Oswald';
  letter-spacing: 2px;
  font-size: 16px;
  font-weight: 800;
  line-height: 1.3;
  text-transform: uppercase;

}

.MuiButton-root.sign_in_button.MuiButtonBase-root.Mui-disabled,
.MuiButton-root.reset_button.MuiButtonBase-root.Mui-disabled,
.MuiButton-root.generate_code.MuiButtonBase-root.Mui-disabled,
.MuiButton-root.ok_button.MuiButtonBase-root.Mui-disabled {
    background-color: #EF0048;
    opacity: 0.5;
}

#icon-circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle {
  stroke: #4CC790;
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.left-1x10 {
  left: 10%;
}

.MuiInputBase-input {
  color: #fff !important;
  font-family: 'Oswald' !important;
}

.MuiButton-root.settings__button {
  padding: 15px 10px;
  min-width: 250px;
  text-transform: none;
  background-color: #EF0048;
  color: #fff;
  font-family: 'Oswald';
  letter-spacing: 0;
}

button[data-testid="Search-iconButton"] span,
button[data-testid="Download CSV-iconButton"] span,
button[data-testid="View Columns-iconButton"] span,
button[data-testid="Filter Table-iconButton"] span {
  position: relative;
  color: #fff;
}

button[data-testid="Search-iconButton"] svg,
button[data-testid="Download CSV-iconButton"] svg,
button[data-testid="View Columns-iconButton"] svg,
button[data-testid="Filter Table-iconButton"] svg {
  display: none;
}

button[data-testid="Search-iconButton"] span:before {
  content: '';
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHXSURBVHgBtZTdbcIwFIWdEMETEhs0I9ANwgSFCYAN6BMSPwqVAIknsgEwQWEC0g0yQkbgASQQAnpOakemDZBI5UiG5Dr+fH98bYgEdbtdxzTNt/P57OC1RJthGCFGANtqNBr54oYM/cV1Xft0Os3w6Ig7AniODT+g8CYQsDJ2X18uF3q0wfCw0C8UCtGiw+FgY77BzWC36TE2r43H4+APkJ5JmI3X5Xa7bXqet0nyrt1u2/l8fopvq9w4l8u96p5a/AFsqmDD4bAm7mgymXBxrd/vfxIqU1RR8yYLwAmM8Hg8vouUQg6bCJtROGRodrMexY5Ey91TCWFu4IQnIfUYCGOZDzwOIqOQv5Vc68RAjAj4u1opvYzWsOo68F/FkEM+oLovIqN4dvkPRhwdi+LzYb/fV0VGIXcRkIc8BsK4kMYWVBLZ5EoPlzFQNnrAg10sFt20JJy9AdcwZWAsYiB/UP7okGKyxQ8fwXq9HvtceXfVWfHlgFZqYHIWGX+uqgHGl+pTFKCE+To3lW0qJDDY7XYV1ftX1xcb37KstX6uZHsJeQspSIioWuhjemrrUCMppE6n05DtxCoqEMHM9VzlTHdAQROBulTl711nWlT+Q2AaKah4hr4BID8U1B8q8e0AAAAASUVORK5CYII=');
  background-size: cover;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
}

button[data-testid="Download CSV-iconButton"] span:before {
  content: '';
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAVCAYAAABR915hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAH+SURBVHgBtVY9SwNBEH0mgimvES0EUwhaCEkhxE67FBaxt0jpb7HMT7C0tNNCMJ0WQlIEFBQuYHEBCwMpIkRwHjub25i9j2B88Ehub3fe7Mzs3K5gMZSFe8JtYUnHxsKBsCMMc9rBSs55gfBUBdMQCa+En1iCcFVYx+wOKTB0nHId4vtbmAgkYhXp2BQ2nOcHYVuNuwjUwSN1kGu46zDJcBHJoLEzxDu9FD4JJ565YxVhrndgNlQWdhPme4W54BjG+0DHboQ9ZOND+K3iJRXt+ya6oU4qIIbsEfnBdNjKP4RJzRyKjmgTJqcEQ/cmfBXeCUe/1tHwls4vYb6KOWZDPlR7M3Vhd9xEHNakAnJRd+aHmC8i97nhjF1bJwsw1WiN3MMchTTRPIg8NsrCc2hUV1UY6kkby8MFzIYY9prq8D+j22KO6+rAs/AlxRAL5QAmv5uI00Rj6zpOoXdnDXc9UrtDncN1kwLiczpEOtiJNoQVZ40Vrui7TsZ6e7SqBcS5WEM6OI9NJPK8i/RdVm2E+htQeGC9QDZ84nlFiWmkipg9c1+YzZEP7EY9XTNaQJQ4Ub2Qwmxz+44DRD+neHcBUZ7nsv5v288iq7SJOBQ8WqzECH8Hbe8i7hW03XK/x7ZtBvg/TC8KvosAi6yGuG8vA6Fy2qCybiDL2L33GvQDXch1lWfjx1gAAAAASUVORK5CYII=');
  background-size: cover;
  top: 0;
  left: 0;
  width: 30px;
  height: 20px;
}

button[data-testid="View Columns-iconButton"] span:before {
  content: '';
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACUSURBVHgB7ZTBDYAgDEWLYQBHwA0dgARGciN1A5xAIWkNIKgHLpq+pEn5NP+HAwVgfo+ID9baGRrgfQbqZXanoDFdSZRSKip/nIImhBhJCz2OTtns1askaq1X6v1zHbaOdGPM5kMSDWfh1QtawgEc8IGAfBft2C6R3GM5rJoWUOhz+hZ/MpR3Epk+aQlJQG2fMMwtB7A4ILcKsHzzAAAAAElFTkSuQmCC');
  background-size: cover;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
}

button[data-testid="Filter Table-iconButton"] span:before {
  content: '';
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFESURBVHgB7VPJbYNAFB0WIQ4c6CCUEBeARG4+hg5wB+QMCCOWa9JBnGNOLgFLFBCXQAnJDYktbxKQrDFLZCWKLPGkEX/+8h7zBghZ8N/g2ITnefumaW7JZTjEcbw5TYhsR9u2GsdxGrkAmDXY3JlAWZamJElvaFax3cqyvCMTKIrCon1Y71VV3bF1bmgINlkQeKZDgiCsgiDIh/p839dg59fLYFmw54Xt4YcGwzDcwaYnhCoIUtu21RHytD/pEPmoQCfygMeB3omiKHu2DvJHWsM6RlEUjPGMClDgPjYgyBEajuNs+zyNkb+nNfhuTnFwZAYgM2BXSuO6rk1RFNXufugpVkmSHKfmhRl+kmVZruv6B0TWPM+v6RNpmXz7/jo3P3uCHq7rtqd7+P6jWZ78MRaBKxLofriz+FeBT/OGLrLgqvAJk0CYW1CkUsMAAAAASUVORK5CYII=');
  background-size: cover;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
}

.MuiDialog-paper.MuiPaper-root {
  border-radius: 10px;
}

@media screen and (max-width: 769px) {
  .MuiDialog-paper.MuiPaper-root {
    margin: 0;
    width: 95%;
  }
}
