.MuiButton-root.message__btn {
  padding: 6px 16px;
  color: #fff;
  background-color: #EF0048;
  font-family: 'Oswald';
  padding: 5px 20px
}
.search .MuiFormLabel-root {
  color: rgba(255, 255, 255, 0.5);
  font-family: 'Oswald';
}

.search .MuiInputBase-input {
  color: rgba(255, 255, 255, 0.5);
  font-family: 'Oswald';
}

.message-editor .rdw-editor-toolbar {
  background-color: #111617;
  border-color: rgba(255, 255, 255, 0.5);
}
.message-editor .rdw-option-wrapper {
  background-color: #EF0048;
  border: 1px solid #EF0048;
  color: #fff;
}

.message-editor .rdw-dropdown-wrapper,
.message-editor .rdw-dropdown-optionwrapper {
  background-color: #EF0048;
  border: 1px solid #EF0048;
  color: #fff;
}

.message-editor .rdw-dropdownoption-active,
.message-editor .rdw-dropdownoption-highlighted  {
  color: #EF0048;
}

.inbox-message {
  height: calc(100vh - 80px) !important;
  overflow: hidden;
}

.inbox-message .text_field {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.inbox-message .text_field input {
  padding: 0.5rem 1rem;
  font-family: 'Oswald';
  font-weight: 400;
}

.inbox__chat .react-input-emoji--container {
  background-color: #181F20;
  border: 0;
  padding: 0;
  margin: 0;
  min-height: 15px;
  border-radius: 25px !important;
}

.inbox__chat .react-input-emoji--wrapper {
  background-color: #181F20;
  color: #fff;
  border-radius: 25px !important;
}

.inbox__emoji-input .react-emoji {
  flex-direction: row-reverse;
}

.inbox__emoji-input .react-emoji-picker--wrapper {
  right: auto;
  left: 0;
}

.MuiDialog-paperWidthSm {
  width: 80%;
}