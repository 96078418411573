.forgot_password {
    height: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.forgot_password .reset_button:hover {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    background-color: #d5d5d5;
}
