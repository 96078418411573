.next-vault table .edit_details_button {
    font-size: 14px;
    font-family: 'Oswald';
    font-weight: 500;
    letter-spacing: -0.23px;
    line-height: 20px;
    border-radius: 5px;
    text-transform: unset;
    padding: 8px 18px;
    background-color: #EF0048;
    color: #FFF;
}