.profile-page__newsfeed {
    display: flex;
    justify-content: space-between;
}
.profile-page__newsfeed .newsfeed__tips {
    background: #111617;
    border-radius: 10px;
}
.profile-page__newsfeed .newsfeed__info {
    background: #111617;
    border-radius: 10px;
}
.profile-page__newsfeed .user-deal__newsfeed,
.profile-page__newsfeed .newsfeed_table > div {
    background: #111617;
    border-radius: 10px;
}
.tips__heading h2 {
    font-size: 20px;
}
.tips__image {
    width: 82px;
    padding: 24px;
    border-radius: 100%;
}
.tips__image .icon-dollars {
    width: 35px;
}
.tips__image .icon-stack {
    width: 32px;
}
.tips__text span:first-of-type {
    font-size: 34px;
}
.tips__text span:last-of-type {
    font-size: 16px;
}
.bg--blue {
    background: #4A90E2;
}
.bg--light-green {
    background: #7ED321;
}
.newsfeed__info h2 {
    font-size: 20px;
    font-family: "Oswald";
}
.newsfeed__social-links a {
    width: 24px;
}

.newsfeed_table {
    position: relative;
    width: 100%;
    background-color: white;
    background-color: #111617;
}