.button_div > button {
    min-width: 0;
    margin: 0 0.5rem;
    padding: 10px 20px;
    font-size: 13px;
    text-transform: none;
    font-family: 'Oswald';
}

.button_div .change_password {
    background-color: #EF0048;
    color: #fff !important;
}

.button_div .cancel_button {
    background-color: #fff;
    /* border: 1px solid #EF0048; */
    color: #EF0048;
    font-family: 'Oswald';
}

.change-password__content {
    min-width: 400px;
}

.MuiDialog-paper.MuiPaper-root {
    background-color: #111617;
}

@media all and (max-width: 767px) {
    .change-password__content {
        min-width: 320px;
    }
}
