.close_button {
    margin-left: 20px;
    width: 30px;
    height: 30px;
    padding: 0;
}

.close_button.MuiIconButton-root {
    padding: 0;
}

.close_button .icon {
    width: 20px;
}