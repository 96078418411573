.MuiPaper-root.data-table {
    background-color: #111617;
}
.table > div {
    box-shadow: unset;
    background-color: #111617;
}

.table > div > div[role='toolbar'] {
    padding: 0;
}

.table > div > div:nth-child(3) {
    background: #111617;
}

.table > div > div[role='toolbar'] {
    font-family: 'Oswald';
    font-size: 24px;
    line-height: 28px;
    color: #fff;
    text-align: left;
    display: flex;
    margin-bottom: 10px;
}

.table div[role='toolbar'] h6 {
    font-family: 'Oswald';
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #fff;
    padding: 0.875rem
}

.table div[role='toolbar'] > div:first-child {
    width: 40%;
    flex: unset;
}

.table div[role='toolbar'] > div > div > button {
    margin: -2px 0 0 10px;
}

.table div[role='toolbar'] > div:first-child > div:first-child {
    justify-content: flex-end;
}

.table div[role='toolbar'] > div:first-child > div:first-child > svg + div {
    flex: 1;
}

.table div[role='toolbar'] > div > div > svg {
    background: #003B42;
    border-radius: 5px 0 0 5px;
    margin: 0;
    height: 41px;
    width: 40px;
    padding: 10px;
    fill: #ffff;
}

.table div[role='toolbar'] div[data-test-id="Search"]:before,
.table div[role='toolbar'] div[data-test-id="Search"]:after {
    border: unset;
}

.table div[role='toolbar'] input {
    border: 1px solid #69696933;
    border-radius: 0 5px 5px 0;
    color: #696969;
    padding: 10px 20px;
}

table thead tr th > span > div > div:nth-child(1),
table thead tr th div {
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: rgba(255, 255, 255, 0.5);
    background: #111617;
}

table thead tr th {
    position: relative;
}

table thead tr th.MuiTableCell-root {
    font-family: 'Oswald';
    font-weight: bold;
    padding: 10px 16px !important;
    background-color: #111617;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

table thead tr th div > div:first-child,
table tbody tr td > div:last-child {
    width: 100%;
}

table thead tr th div .MUIDataTableHeadCell-sortActive-155 {
    color: #fff;
}

table thead tr th > span > div > div:nth-child(2) {
    align-items: center;
    position: absolute;
    right: 0;
    top: 24px;
}

.table table tbody tr > td {
    border: unset;
    font-size: 1rem;
    font-family: 'Oswald';
    color: #fff;
}

.table table .no_data_table {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table .MuiTablePagination-toolbar {
    color: #fff;
}

.table .MuiButton-text {
    color: #fff;
    font-family: 'Oswald';
}

.table .MuiButton-text.view_details_button {
    border: 1px solid #858787;
}

.profile-page__deals .MuiTypography-h6 {
    color: rgba(255, 255, 255, 1);
}

.profile-page__deals .MuiIconButton-label {
    color: rgba(255, 255, 255, 0.5);
}

.profile-page__deals .MuiTablePagination-toolbar,
.profile-page__deals .MuiSelect-icon,
.profile-page__deals .MuiTableCell-body {
    color: rgba(255, 255, 255, 0.5); 
}

.profile-page__deals.bg-custom-dark {
    background-color: inherit;
}

.data-table.MuiPaper-elevation4 {
    box-shadow: none;
}

.data-table .MuiTableBody-root .MuiTypography-root {
    font-family: 'Oswald';
    font-size: 1rem;
    font-weight: 700;
    opacity: 0.6;
    border: none;
}
.MuiIconButton-label {
    color: rgba(255, 255, 255, 0.5);
}
.data-table .MuiTableBody-root tr:last-child .MuiTableCell-root,
.data-table .MuiTableFooter-root tr:last-child .MuiTableCell-root {
    border: none;
}

table tbody tr > td {
    border: unset;
    font-size: 1rem;
    font-family: 'Oswald';
    color: #fff;
}

.table th.MuiTableCell-root {
    border-bottom: 1px solid
    rgba(255, 255, 255, 0.4);
}
tfoot .MuiTableRow-footer .MuiTableCell-footer {
    border-bottom: 1px solid
    rgba(255, 255, 255, 0.4);
}

@media (max-width: 769px) {
    .table div[role='toolbar'] > div:first-child {
        width: 50%;
        width: auto;
    }

    .table div[role='toolbar'] h6 {
        font-size: 20px;
    }

    .table table tbody tr > td > div:first-child {
        width: 30%;
        text-align: left;
        font-weight: bold;
    }

    .table table tbody tr > td > div {
        font-size: 0.75rem;
        vertical-align: middle;
    }

    .table > div > div[role='toolbar'] {
        flex-direction: column;
        padding: 20px 0 10px 0;
    }

    .table div[role='toolbar'] > div:first-child {
        margin: 0;
        padding: 0;
    }

    .table div[role='toolbar'] h6 {
        padding: 0;
    }

    .table tfoot .MuiTableRow-footer .MuiTableCell-footer {
        border: none;
    }
    .table tr.MuiTableRow-root {
        border-top: solid 2px rgba(255, 255, 255, 0.15);
        border-bottom: solid 2px rgba(255, 255, 255, 0.15);
    }

    tr.MuiTableRow-root td:first-child {
        padding-top: 10%;
    }
    
    tr.MuiTableRow-root td:last-child {
        padding-bottom: 10%;
    }
    .table .MuiTableFooter-root  tr.MuiTableRow-root {
        border: none;
    }
    .table table tbody tr > td > div {
        font-size: 16px;
    }
    .table table tbody tr > td > div:first-child {
        margin-right: 20px;
    }
}

@media (max-width: 426px) {
    .table div[role='toolbar'] h6 {
        font-size: 20px;
    }
}

@media (max-width: 376px) {
    .table div[role='toolbar'] button {
        padding: 10px;
    }
}
