.settings .heading_text {
    font-family: 'Roboto', sans-serif;
    font-size: 34px;
    line-height: 40px;
    color: #000000;
}

.settings .tabs_section {
    display: flex;
    width: 100%;
    margin: 30px 0;
}

.settings .card {
    background: #FFFFFF;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 50px 40px;
}

.settings .card .row {
    display: flex;
    align-items: center;
}

.settings .row .sub_heading {
    font-family: Roboto, sans-serif;
    font-size: 20px;
    line-height: 23px;
    color: #696969;
    width: 40%;
}

.settings .row .text_field {
    width: unset;
}

.settings .change_password {
    border: 1px solid #003B42;
    box-sizing: border-box;
    border-radius: 30px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #003B42;
    text-transform: unset;
    padding: 10px 20px;
    margin: 20px 0 0 40%;
    background: #FFFFFF;
}

.settings .tab_content {
    flex: 1;
}

.settings .tab_content > div {
    padding: 0 0 0 24px;
}

.settings .vertical_tabs {
    border-right: 1px solid #D8D8D8;
}

.settings .vertical_tabs > div > span {
    background: #000000;
}

.tab.MuiTab-root,
.tab.MuiTab-root > span {
    text-transform: none;
    font-family: 'Oswald';
    font-size: 1rem;
    text-align: left;
    justify-content: left;
    font-weight: 600;
}

.vertical_tabs .PrivateTabIndicator-colorSecondary-4 {
    background-color: #4CBDC5;
}

.settings__item-container .MuiSwitch-root {
    padding: 5px;
}

.settings__item-container .MuiSwitch-switchBase {
    top: 2px;
    left: 2px;
}

.settings__item-container .MuiSwitch-thumb {
    width: 15px;
    height: 15px;
    box-shadow: none;
    background-color: #EF0048;
}

.settings__item-container .MuiSwitch-track {
    border-radius: 20px;
    background-color: #000;
    opacity: 1;
    border: 4px solid #EF0048;
}

.settings__item-container .MuiTabs-scroller > span {
    border-radius: 20px;
    background-color: #000;
    opacity: 1;
    border: 4px solid #EF0048;
}

.settings__item-container .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #EF0048;
}

@media (max-width: 769px) {
    .settings.content_padding {
        padding: 30px;
    }

    .settings .vertical_tabs .tab {
        width: 120px;
        min-width: 120px;
    }

    .settings .card {
        padding: 30px;
    }

    .settings .card .row {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
    }

    .settings .row .sub_heading,
    .settings .row .text_field {
        width: 100%;
    }

    .settings .authentication_content .row {
        flex-direction: row;
        align-items: center;
        margin-bottom: unset;
    }

    .settings .authentication_content .sub_heading {
        width: max-content;
        margin-right: 40px;
    }

    .settings .button_div {
        text-align: center;
    }

    .settings .change_password {
        margin: 20px 0 0;
    }
}

@media (max-width: 611px) {
    .settings .tabs_section {
        flex-direction: column;
    }

    .settings .vertical_tabs {
        border: unset;
    }

    .settings .vertical_tabs > div > div {
        flex-direction: row;
    }

    .settings .vertical_tabs > div > span {
        display: none;
    }

    .settings .vertical_tabs .tab.active {
        background: #003b420f;
    }

    .settings .tab_content > div {
        padding: 0;
    }

    .settings .card {
        box-shadow: unset;
    }

    .settings .vertical_tabs .tab {
        width: 50%;
    }
}

@media (max-width: 426px) {
    .settings .authentication_content .sub_heading {
        margin: unset;
    }
}
