.timezone-select > span + span + div {
    background-color: transparent;
    border: none;
    box-shadow: unset;
    border-radius: 3px;
    padding: 0.5rem 1rem;
}

.timezone-select > span + span + div + div {
    background-color: #0C0F0F;
    color: #fff;
}

.css-1dimb5e-singleValue {
    color: #fff !important;
}