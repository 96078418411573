.profile {
    display: flex;
    align-items: center;
}
.profile img {
    background: #FFFFFF;
    border-radius: 100%;
}
.profile .profile_button,
.profile .profile_button:hover {
    background: transparent;
    color: #ffffff8a;
    box-shadow: unset;
}

.profile_button svg {
    width: 20px;
    margin-left: 6px;
}

/* popover */
.profile_popover > div:first-child {
    width: 100%;
    top: 0 !important;
}

.profile_popover > div {
    width: 300px;
    border-radius: 5px;
    padding: 20px 0;
}

.profile_popover .list_button {
    font-size: 16px;
    line-height: 19px;
}

.profile_popover .list_button > button {
    width: 100%;
    padding: 10px;
    text-transform: capitalize;
    color: #fff;
}

.profile .profile_button .name {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    margin-left: 10px;
    text-transform: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}

.profile_popover .icon {
    width: 32px;
    height: 32px;
    margin: 0 10px;
    color: #fff;
}

.profile_popover .list_button button > span:first-child {
    display: flex;
    justify-content: flex-start;
    margin-left: 4px;
}

div[role='presentation'].profile_popover > div[tabindex='-1'] {
    min-width: unset;
    background-color: #111617;
}

.profile__info .name {
    color: #fff;
    font-family: Oswald;
    font-size: 1.25rem;
    font-weight: bold;
}

.profile__info img {
    background: #fff;
    border-radius: 100%;
}

.profile__info span {
    color: #909090;
}

.profile__separator {
    border-color: #909090;
}

.navigation__profile {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    overflow: hidden;
}

.navigation__profile img {
    width: 40px;
    height: 40px;
    object-fit: cover;
}

@media all and (max-width: 1440px) {
    .profile .profile_button .name {
        max-width: 50px;
    }

    .tabs_content .MuiTab-root {
        margin-right: 5px;
    }
}

@media (max-width: 1024px) {
    .profile .profile_button .name {
        font-size: 15px;
        line-height: 1;
    }
}

@media all and (max-width: 1023px) {
    .profile .profile_button .name {
        max-width: 100px;
    }
 }

@media (max-width: 768px) {
    .profile .profile_button {
        width: 100%;
    }
}