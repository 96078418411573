.authentication .reset_button {
    color: #fff;
    background-color: #003B42;
    box-shadow: none;
    width: 100%;
    margin-top: 20px;
    font-weight: 300;
    text-align: center;
    padding: .429rem 1rem;
    font-size: 1rem;
    line-height: 1.571429;
    text-transform: unset;
    border-radius: .215rem;
}

.authentication .reset_button:hover {
    color: #fff;
    background-color: #003b42bf;
    box-shadow: none;
}
