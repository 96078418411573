.console .select_field {
  margin: 0;
}

.select_field input {
  color: #76838f;
}

.select_field > div {
  box-shadow: unset;
  border-radius: 3px;
  padding: 0.5rem 1rem;
}

.select_field > div:before,
.select_field > div:after,
.dialog .select_field > div:hover:before {
  border: unset;
}

.select_field > div > div {
  color: #76838f;
  padding: 0;
}

.select_field > div > div:focus {
  background: unset;
}

/* scroll bar */
div[role='presentation'] > div[tabindex='-1']::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 4px;
}

div[role='presentation'] > div[tabindex='-1']::-webkit-scrollbar-thumb {
  border: 0 solid #fff;
  box-shadow: unset;
  border-radius: 20px;
  background: #fff;
}
