.contributor_details .card {
    background: #FFFFFF;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin: 30px 0;
    padding: 50px 40px;
}

.contributor_details .row {
    display: flex;
    margin-bottom: 40px;
}

.contributor_details .row.align_center {
    align-items: center;
}

.contributor_details .row .heading {
    font-family: Roboto,sans-serif;
    font-size: 20px;
    line-height: 23px;
    color: #696969;
    width: 40%;
}

.contributor_details .row .id_text {
    font-family: Roboto,sans-serif;
    font-size: 20px;
    line-height: 23px;
    color: #3E8EF7;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}

.contributor_details .row a.id_text {
    text-decoration: unset;
}

.contributor_details .row a.id_text:hover {
    text-decoration: underline;
}

.contributor_details .row .value {
    font-family: Roboto,sans-serif;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
}

.contributor_details .row .text_area {
    background: #F5F5F5;
    border-radius: 5px;
    padding: 20px 30px;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    word-break: break-word;
    width: 60%;
}

.contributor_details__header {
    border-color: rgba(0,0,0,0.05);
}

.icon_div--responsive .MuiButtonBase-root {
    padding: 0;
    height: auto;
}

pre {
    white-space: pre-wrap;
}
